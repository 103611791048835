<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Review Proposal</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceRequest.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>
                    
        <JobInformation 
            :id="serviceRequest.id"
            :project="projectSelected"
            :refreshJob="refreshJob"
            :showJobSummary="true"
            :showReviewReason="true"
            :showButtonsCreateEditProject="false"
            :showButtonsCreateEditVersion="false"
            :showQuickAccess="false"
            v-on:changeProject="updateProject"
        />

        <v-row
            v-if="projectSelected != null && projectSelected != undefined"
        >
            <v-col 
                cols="12"
                style="padding: 0px;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Selected Items
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-simple-table
                                fixed-header
                                style="cursor: pointer;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" ></th>
                                            <th class="text-left" style="width: 10%">
                                                Category / Sub Category
                                            </th>
                                            <th class="text-left" style="width: 15%">
                                                Product
                                            </th>
                                            <th class="text-left" style="width: 15%">
                                                Description
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Notes
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Styling
                                            </th>
                                            <th class="text-center" style="width: 5%">
                                                Qty
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                                Price
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                                Total
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automation == false || prd.notAutomatable == 1)" :key="index"
                                            @click="focusInput(item)"
                                        >
                                            <td>
                                                <v-icon 
                                                    v-if="item.needsApproval == 1"
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-package-variant-closed-check
                                                </v-icon>
                                                <v-icon 
                                                    v-if="item.priceDefinedByAdmin == 1"
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-currency-usd-off
                                                </v-icon>
                                            </td>

                                            <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                            
                                            <td>
                                                {{ item.productDescription }}
                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.customOrder == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.customOrder == 1"
                                                            style="color: var(--color__alert); margin-left: 5px;" 
                                                        >
                                                            mdi-package-variant-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Custom Order</span>
                                                </v-tooltip>

                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.stylable == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.stylable == 1"
                                                            style="color: var(--color__blue); margin-left: 5px;" 
                                                        >
                                                            mdi-palette-swatch-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Stylable</span>
                                                </v-tooltip>
                                            </td>
                                            <td>{{ item.productDetails }}</td>
                                            <td v-html="item.note"></td>
                                            <td v-html="item.styling"></td>
                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                            <td class="text-right">$ {{ item.price }}</td>
                                            <td class="text-right">$ {{ item.total }}</td>
                                            <td>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Image</span>
                                                </v-tooltip>

                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Docs</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot v-if="qtyChannels == 0">
                                        <tr>
                                            <th 
                                                colspan="10" 
                                                style="border-top: solid 1px silver; padding-top: 10px;"
                                            >
                                                Legend <br />
                                                <v-icon 
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-package-variant-closed-check
                                                </v-icon>
                                                <label style="font-style: italic;font-weight: normal;">Items that need approval</label> 
                                                <br />
                                                <br />
                                                <v-icon 
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-currency-usd-off
                                                </v-icon>
                                                <label style="font-style: italic;font-weight: normal;">Items that must be priced</label>
                                            </th>
                                        </tr>
                                        <tr style="border-top: solid 1px gray !important;">
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Total
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ grandTotalProducts }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Discount
                                            </th>
                                            <th style="display: flex;">
                                                <v-text-field
                                                    name="discount"
                                                    v-model="discount"
                                                    suffix=" %"
                                                    v-formatMoney="percFormat"
                                                    :rules=[validations.required]
                                                    single-line
                                                    outlined
                                                    dense
                                                    @blur="calculateTotal('d')"
                                                >
                                                </v-text-field>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Grand Total
                                            </th>
                                            <th style="display: flex;">
                                                <v-text-field
                                                    name="grandTotal"
                                                    v-model="grandTotalProducts" 
                                                    v-formatMoney="moneyFormat"
                                                    :rules=[validations.required]
                                                    single-line
                                                    outlined
                                                    dense
                                                    @blur="calculateTotal('v')"
                                                >
                                                </v-text-field>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Budget
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ serviceRequest.estimatedBudgetFormatted }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Zillow
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ estimatedValueZillow }}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>

                <v-card-text
                    v-if="qtyChannels > 0"
                >

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Automation
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <label>Automation Controller</label>
                            <v-simple-table
                                fixed-header
                                style="cursor: pointer;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" ></th>
                                            <th class="text-left" style="width: 10%">
                                                Category / Sub Category
                                            </th>
                                            <th class="text-left" style="width: 15%">
                                                Product
                                            </th>
                                            <th class="text-left" style="width: 15%">
                                                Description
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Notes
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Styling
                                            </th>
                                            <th class="text-center" style="width: 5%">
                                                Qty
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                                Price
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                                Total
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automationCenter == 1)" :key="index"
                                            @click="focusInput(item)"
                                        >
                                            <td>
                                                <v-icon 
                                                    v-if="item.needsApproval == 1"
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-package-variant-closed-check
                                                </v-icon>
                                            </td>

                                            <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                            
                                            <td>
                                                {{ item.productDescription }}
                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.customOrder == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.customOrder == 1"
                                                            style="color: var(--color__alert); margin-left: 5px;" 
                                                        >
                                                            mdi-package-variant-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Custom Order</span>
                                                </v-tooltip>

                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.stylable == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.stylable == 1"
                                                            style="color: var(--color__blue); margin-left: 5px;" 
                                                        >
                                                            mdi-palette-swatch-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Stylable</span>
                                                </v-tooltip>
                                            </td>
                                            <td>{{ item.productDetails }}</td>
                                            <td v-html="item.note"></td>
                                            <td v-html="item.styling"></td>
                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                            <td class="text-right">$ {{ item.price }}</td>
                                            <td class="text-right">$ {{ item.total }}</td>
                                            <td>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Image</span>
                                                </v-tooltip>

                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Docs</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                        
                        <v-card-text style="margin-top: 20px;">
                            <label>Automation Items</label>
                            <v-simple-table
                                fixed-header
                                style="cursor: pointer;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" ></th>
                                            <th class="text-left" style="width: 10%">
                                                Category / Sub Category
                                            </th>
                                            <th class="text-left" style="width: 20%">
                                                Product
                                            </th>
                                            <th class="text-left" style="width: 20%">
                                                Description
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Notes
                                            </th>
                                            <th class="text-left" style="width: 10%">
                                                Styling
                                            </th>
                                            <th class="text-center" style="width: 5%">
                                                Qty
                                            </th>
                                            <th class="text-right" style="width: 7%">
                                                Price
                                            </th>
                                            <th class="text-right" style="width: 7%">
                                                Total
                                            </th>
                                            <th class="text-right" style="width: 10%">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in listProductSelecteds.filter(prd => prd.automatable == 1 && prd.notAutomatable == 0)" :key="index"
                                            @click="focusInput(item)"
                                        >
                                            <td>
                                                <v-icon 
                                                    v-if="item.needsApproval == 1"
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-package-variant-closed-check
                                                </v-icon>
                                            </td>
                                            <td>{{ item.categoryDescription }} {{ item.subCategoryDescription != null && item.subCategoryDescription != undefined && item.subCategoryDescription != '' ? ' / ' + item.subCategoryDescription : '' }}</td>
                                            <td>
                                                {{ item.productDescription }}
                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.customOrder == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.customOrder == 1"
                                                            style="color: var(--color__alert); margin-left: 5px;" 
                                                        >
                                                            mdi-package-variant-plus
                                                        </v-icon>
                                                    </template>
                                                    <span>Custom Order</span>
                                                </v-tooltip>

                                                <v-tooltip 
                                                    bottom
                                                    v-if="item.stylable == 1"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon 
                                                            v-on="on"
                                                            v-if="item.stylable == 1"
                                                            style="color: var(--color__blue); margin-left: 5px;" 
                                                        >
                                                            mdi-palette-swatch-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>Stylable</span>
                                                </v-tooltip>
                                            </td>
                                            <td>{{ item.productDetails }}</td>
                                            <td v-html="item.note"></td>
                                            <td v-html="item.styling"></td>
                                            <td class="text-center">{{ item.qty }} <span style="font-size: 10px; font-weight: bold;">{{ item.unitySymbol }}</span></td>
                                            <td class="text-right">$ {{ item.price }}</td>
                                            <td class="text-right">$ {{ item.total }}</td>
                                            <td>
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsImages(item)">mdi-folder-multiple-image</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Image</span>
                                                </v-tooltip>

                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn 
                                                            class="btnActions"
                                                            style="width: 35px; height:35px !important;"
                                                            icon 
                                                            small 
                                                            outlined
                                                            v-on="on"
                                                        >
                                                            <v-icon small @click="showProductsDocs(item)">mdi-dock-window</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>View Docs</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th 
                                                colspan="10" 
                                                style="border-top: solid 1px silver; padding-top: 10px;"
                                            >
                                                Legend <br />
                                                <v-icon 
                                                    small 
                                                    color="var(--color__red)" 
                                                    left
                                                >
                                                    mdi-package-variant-closed-check
                                                </v-icon>
                                                <span style="font-style: italic;font-weight: normal;">Items that need approval</span>
                                            </th>
                                        </tr>
                                        <tr style="border-top: solid 1px gray !important;">
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Total
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ grandTotalProducts }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Discount
                                            </th>
                                            <th style="display: flex;">
                                                <v-text-field
                                                    name="discount"
                                                    v-model="discount"
                                                    suffix=" %"
                                                    v-formatMoney="percFormat"
                                                    :rules=[validations.required]
                                                    single-line
                                                    outlined
                                                    dense
                                                    @blur="calculateTotal('d')"
                                                >
                                                </v-text-field>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Grand Total
                                            </th>
                                            <th
                                                style="display: flex;"
                                            >
                                                <v-text-field
                                                    name="grandTotal"
                                                    v-model="grandTotalProducts" 
                                                    v-formatMoney="moneyFormat"
                                                    :rules=[validations.required]
                                                    single-line
                                                    outlined
                                                    dense
                                                    @blur="calculateTotal('v')"
                                                >
                                                </v-text-field>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Budget
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ serviceRequest.estimatedBudgetFormatted }}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-left"></th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                Zillow
                                            </th>
                                            <th class="text-right" style="color: var(--color__main); font-size: 20px;">
                                                {{ estimatedValueZillow }}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>                    
                </v-card-text>
            </v-col>
        </v-row>
            
        <v-row
            v-if="projectSelected != null && projectSelected != undefined"
        >
            <v-col
                cols="12"
                lg="7"
                md="12"
                sm="12"
            >
                &nbsp;
            </v-col>
            <v-col
                cols="12"
                lg="5"
                md="12"
                sm="12"
            >
                <zillowTerms :address="serviceRequest.addressSelected.address" :lgWidthTerms="'8'" :lgWidthLogo="'4'"  />
            </v-col>
        </v-row>
        
        <v-row
            v-if="projectSelected != null && projectSelected != undefined"
        >
            <v-col
                cols="12"
                style="padding: 0px;"
            >
                <v-expansion-panels 
                    v-model="panels"
                    popout
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-icon color="primary" left>
                                        mdi-information-variant
                                    </v-icon>
                                    Items
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card-text>

                                <v-card
                                    class="mx-auto"
                                >
                                    <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                        <v-text-field
                                            v-model="searchProduct"
                                            label="Search Product"
                                            prepend-inner-icon="mdi mdi-magnify"
                                            single-line
                                            outlined
                                            dense
                                        ></v-text-field>
                                    </v-card-title>
                                    <v-card-text style="margin-top: 20px;">
                                        <v-treeview
                                            :items="listTreeViewProduct"
                                            :search="searchProduct"
                                            :open.sync="arrayNode"
                                            @update:open="onOpenTreeView"
                                            :transition="true"
                                            open-on-click
                                            rounded
                                            hoverable
                                        >
                                            <template v-slot:prepend="{ item }">
                                                <v-icon style="color: var(--color__main);" v-if="item.type == 'Category'">
                                                    mdi-shape-outline
                                                </v-icon>
                                                <v-icon style="color: var(--color__main);" v-if="item.type == 'SubCategory'">
                                                    mdi-content-duplicate
                                                </v-icon>
                                                <v-icon style="color: var(--color__cinza_escuro);" v-if="item.type == 'Product'">
                                                    mdi-package-variant-closed
                                                </v-icon>
                                            </template>
                                            <template v-slot:label="{ item }">
                                                <v-row>
                                                    <v-col
                                                        cols="6"
                                                    >
                                                        <span :style="item.type == 'Category' || item.type == 'SubCategory' ? 'color: var(--color__main);' : 'color: var(--color__cinza_escuro);'"> 
                                                            {{ item.name }} 
                                                            <span style="font-size: 20px; color: red; font-weight: bold;" v-if="isProductMandatory(item) == true || isCategoryOrSubCategoryMandatory(item) == true"> *</span> 
                                                            <span style="font-size: 10px;" v-if="item.details != ''"><br />{{ item.details }}</span>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.highlight == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.highlight == 1"
                                                                        style="color: var(--color__alert); margin-left: 5px;" 
                                                                    >
                                                                        mdi-alert-octagram-outline
                                                                    </v-icon>
                                                                </template>
                                                                <span>{{ item.highlightDescription }}</span>
                                                            </v-tooltip>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.customOrder == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.customOrder == 1"
                                                                        style="color: var(--color__alert); margin-left: 5px;" 
                                                                    >
                                                                        mdi-package-variant-plus
                                                                    </v-icon>
                                                                </template>
                                                                <span>Custom Order</span>
                                                            </v-tooltip>

                                                            <v-tooltip 
                                                                bottom
                                                                v-if="item.stylable == 1"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon 
                                                                        v-on="on"
                                                                        v-if="item.stylable == 1"
                                                                        style="color: var(--color__blue); margin-left: 5px;" 
                                                                    >
                                                                        mdi-palette-swatch-outline
                                                                    </v-icon>
                                                                </template>
                                                                <span>Stylable</span>
                                                            </v-tooltip>

                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        style="text-align:right;"
                                                        v-if="item.type == 'Category' || item.type == 'SubCategory'"
                                                        cols="6"
                                                    >
                                                        {{ item.children.filter(chd => chd.qty != "0.00" && chd.qty != "").length }} / {{ item.children.length }}
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product'"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            v-if="item.unityFieldType == 1"
                                                            :id="'productQty' + item.idTable"
                                                            name="qtyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            v-model="item.qty"
                                                            v-formatMoney="moneyFormat"
                                                            :suffix="item.unitySymbol"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                        >
                                                        </v-text-field>
                                                        <v-text-field
                                                            v-else
                                                            :id="'productQty' + item.idTable"
                                                            name="qtyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            v-mask="'####'"
                                                            v-model="item.qty"
                                                            :suffix="item.unitySymbol"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product'"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            name="moneyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            prefix="$"
                                                            v-model="item.priceFormattedWithoutSymbol"
                                                            v-formatMoney="moneyFormat"
                                                            :rules=[validations.mandatoryProduct(item)]
                                                            dense
                                                            single-line
                                                            outlined
                                                            @blur="updateItemsProduct"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        v-if="item.type == 'Product'"
                                                        style="text-align: right;"
                                                        cols="2"
                                                    >
                                                        <v-text-field
                                                            name="moneyField"
                                                            style="width: 150px; float: right; max-height: 30px; text-align: center;"
                                                            prefix="$"
                                                            v-model="item.total"
                                                            v-formatMoney="moneyFormat"
                                                            disabled
                                                            dense
                                                            single-line
                                                            outlined
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-treeview>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            
        </v-row>

		<v-dialog
			v-model="showDialogDeniedService"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					Explain your reason
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>

						<v-col cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>Reason</label>
                            <v-textarea
                                v-model="descriptionDenied"
                                single-line
                                rows="4"
                                row-height="30"
                                outlined
                            >
                            </v-textarea>
						</v-col>
					</v-row>
					

				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogDeniedService = false"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmDeny()"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>


        <v-card-actions 
            class="justify-center"
            style="padding-top: 50px"
        >
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        class="mx-2"
                        v-on="on"
                        @click="cancel"
                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                    >
                        Cancel
                    </v-btn>
                </template>
                <span>Cancel</span>
            </v-tooltip>
            
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        class="mx-2"
                        v-on="on"
                        :style="projectSelected != null && projectSelected != undefined ? 'background-color: var(--color__red) !important; color: #ffffff !important;' : ''"
                        @click="showDialogDeniedService = true"
                        :disabled="projectSelected == null || projectSelected == undefined"
                    >
                        Deny
                    </v-btn>
                </template>
                <span>Deny Proposal</span>
            </v-tooltip>
            
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="mx-2"
                        v-on="on"
                        @click="confirmApprove()"
                        :disabled="projectSelected == null || projectSelected == undefined"
                    >
                        Approve
                    </v-btn>
                </template>
                <span>Approve Proposal</span>
            </v-tooltip>
        </v-card-actions>
                    

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <ShowFiles 
            :showFiles.sync="dialogShowFiles.show"
            :showAsCarrousel.sync="dialogShowFiles.showAsCarrousel"
            :reload="dialogShowFiles.reload"
            :showFilters="dialogShowFiles.showFilters"
            :listParams="dialogShowFiles.listParams"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import zillowTerms from '@/components/Zillow/ZillowTerms';
    import { formaterDecimalBR } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { formaterDecimalBRServer } from '@/utilities/Utils';
    import JobInformation from "@/components/Services/JobInformation";
    import ShowFiles from "@/components/File/ShowFiles";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT 
    } from "@/utilities/Enums";

    export default ({

        components: {
            zillowTerms,
            ActionDialog,
            JobInformation,
            ShowFiles
        },

        mixins: [Helpers],

        data: vm => ({

            panels: [],

			serviceRequest: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                appointmentCitySelected: {
                    description: "",
                    stateSelected: {
                        code: ""
                    }
                }
            },

            listProjects: [],
            projectSelected: null,
            refreshJob: false,

            listProductSelecteds: [],

            arrayNode: [],
            searchProduct: "",
            listTreeViewProduct: [],
            listTemplate: [],
            templateSelected: null,

            moneyFormat: {
                prefix: '',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: '.',
                precision: 2,
            },

            showMoreDetail: false,

            grandTotalUpdated: false,
            grandTotalProducts: "$ 0.00",
            discount: "0.00",
            grandTotalProductsUnFormatted: 0,
            estimatedValueZillow: "$ 0.00",

            showDialogDeniedService: false,
            descriptionDenied: "",

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogShowFiles: {
                show: false,
                showAsCarrousel: false,
                reload: 0,
                showFilters: false,
                listParams: []
            },
            
            validations: {
                required: required,
                mandatoryProduct (itemProduct) {
                    if (vm.isProductMandatory(itemProduct) == true && (itemProduct.qty == '0.00' || itemProduct.qty == '' || itemProduct.qty == null))
                        return false
                    else
                        return true
                }
            },

            og: Math.pow(10, 2),
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            calculateGrandTotal() {
                try {
                    let grandTotal = this.grandTotalProductsUnFormatted;

                    return `$ ${formaterDecimalBR(grandTotal)}`;
                }
                catch {
                    return `$ 0.00`
                }
            },

            qtyChannels() {
                let centralAutomation = this.listProductSelecteds.filter(prd => prd.automationCenter == 1)[0];

                if (centralAutomation != null && centralAutomation != undefined) {
                    return centralAutomation.channelQty;
                }
                else {
                    return 0;
                }
            },

            automationItemsIsValid() {
                let qtyChannelsAvailable = this.qtyChannels;
                let listAutomatableItems = this.listProductSelecteds.filter(prd => prd.automatable == 1);
                let qtyItemsAutomatable = 0;

                listAutomatableItems.forEach(item => {
                    qtyItemsAutomatable += parseInt(item.qty);
                });

                if (qtyItemsAutomatable > qtyChannelsAvailable) {
                    return false;
                }
                else {
                    return true;
                }
            },

            isProjectPool() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pool == 1;
            },

            isProjectBBQ() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.bbq == 1;
            },

            isProjectPergola() {
                return this.projectSelected != null && this.projectSelected != undefined && this.projectSelected.pergola == 1;
            },
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetById", this.id);

                    this.serviceRequest = response.result;

                    this.discount = formaterDecimalBR(this.serviceRequest.discount);
                    this.grandTotalUpdated = this.serviceRequest.grandTotal > 0;
                    this.grandTotalProducts = this.serviceRequest.grandTotalFormatted;

                    this.getEstimatedValueZillow();
                }
            },

            async getIDProject() {

                let idServiceProject = 0;

                if (this.projectSelected != null && this.projectSelected != null) {
                    idServiceProject = this.projectSelected.id;
                }

                return idServiceProject;
            },

            async updateProject(projectSelected, listProjects) {

                this.projectSelected = projectSelected;
                this.listProjects = listProjects;

                if (projectSelected != null && projectSelected != undefined) {

                    let idServiceProject = await this.getIDProject();

                    this.listTreeViewProduct = await this.$store.dispatch("productModule/ListProductsToScopeOfWork", { id: idServiceProject, type: 'SERVICE', showOnlyCreatedItems: false, fromHistory: false });
                    
                    this.updateItemsProduct();
                }
            },

            async getLists() {
                
                this.listTemplate = await this.$store.dispatch("templateModule/List");
            },

            async validateForm(step) {

                if (step == 1) {
                    if (this.listProductSelecteds.length == 0 ) {
                        this.showToast("error", "Error!", "No products selected. You must select at least one.");
                        return false;
                    }
                    else if (await this.checkMandatory()) {
                        return true;
                    }
                }
                else if (step == 2) {

                    return true;
                }
                else {
                    return true;
                }
            },

            async updateItemsProduct() {

                let idServiceProject = await this.getIDProject();

                if (idServiceProject != 0) {
                
                    this.listProductSelecteds = [];
                    let grandTotal = 0;
                    this.listTreeViewProduct.forEach(itemCategory => {
                        itemCategory.children.forEach(itemSubCategoryOrProduct => {
                            
                            if (itemSubCategoryOrProduct.type == 'SubCategory') {

                                itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                    if (itemProduct.qty != null && itemProduct.qty != undefined && itemProduct.qty != "0.00" && itemProduct.qty != "" ) {

                                        let qty = parseFloat(itemProduct.qty == '' ? 0 : itemProduct.qty);
                                        
                                        if (qty <= 0) {
                                            this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                            itemProduct.qty = '';
                                        }
                                        else {
                                            let price = parseFloat(formaterDecimalBRServer(itemProduct.priceFormattedWithoutSymbol));

                                            let total = (qty * price).toFixed(2);

                                            itemProduct.price = price;
                                            itemProduct.total = formaterDecimalBR(total);

                                            this.listProductSelecteds.push({
                                                id: itemProduct.idServiceItem,
                                                idService: idServiceProject,
                                                idCategory: itemCategory.id,
                                                categoryDescription: itemCategory.name,
                                                idSubCategory: itemSubCategoryOrProduct.id,
                                                subCategoryDescription: itemSubCategoryOrProduct.name,
                                                productDescription: itemProduct.name,
                                                productDetails: itemProduct.details,
                                                idProduct: itemProduct.idTable,
                                                mandatoryPool: itemProduct.mandatoryPool,
                                                mandatoryBBQ: itemProduct.mandatoryBBQ,
                                                mandatoryPergola: itemProduct.mandatoryPergola,
                                                needsApproval: itemProduct.needsApproval,
                                                priceDefinedByAdmin: itemProduct.priceDefinedByAdmin,
                                                qty: itemProduct.qty,
                                                automation: itemProduct.automatable == 1 || itemProduct.automationCenter == 1,
                                                automatable: itemProduct.automatable,
                                                automationCenter: itemProduct.automationCenter,
                                                channelQty: itemProduct.channelQty,
                                                unitySymbol: itemProduct.unitySymbol,
                                                note: itemProduct.note,
                                                styling: itemProduct.styling,
                                                customOrder: itemProduct.customOrder,
                                                stylable: itemProduct.stylable,
                                                price: formaterDecimalBR(itemProduct.price),
                                                total: itemProduct.total,
                                                requiredAtLeastOneProductCategory: itemProduct.requiredAtLeastOneProductCategory,
                                                requiredAtLeastOneProductSubCategory: itemProduct.requiredAtLeastOneProductSubCategory
                                            })
                                            grandTotal += parseFloat(total);
                                        }
                                    }
                                    else {
                                        itemProduct.qty = '';
                                    }
                                });
                            }
                            else {
                                
                                if (itemSubCategoryOrProduct.qty != null && itemSubCategoryOrProduct.qty != undefined && itemSubCategoryOrProduct.qty != "0.00" && itemSubCategoryOrProduct.qty != "" ) {


                                    let qty = parseFloat(itemSubCategoryOrProduct.qty == '' ? 0 : itemSubCategoryOrProduct.qty);

                                    if (qty <= 0) {
                                        this.showToast("error", "Warning!", "Quantity cannot be negative!");
                                        itemSubCategoryOrProduct.qty = ''
                                    }
                                    else {

                                        let price = parseFloat(formaterDecimalBRServer(itemSubCategoryOrProduct.priceFormattedWithoutSymbol));
                                        
                                        let total = (qty * price).toFixed(2);

                                        itemSubCategoryOrProduct.price = price;
                                        itemSubCategoryOrProduct.total = formaterDecimalBR(total);

                                        this.listProductSelecteds.push({
                                            id: itemSubCategoryOrProduct.idServiceItem,
                                            idService: idServiceProject,
                                            idCategory: itemCategory.id,
                                            categoryDescription: itemCategory.name,
                                            idSubCategory: 0,
                                            subCategoryDescription: "",
                                            productDescription: itemSubCategoryOrProduct.name,
                                            productDetails: itemSubCategoryOrProduct.details,
                                            idProduct: itemSubCategoryOrProduct.idTable,
                                            mandatoryPool: itemSubCategoryOrProduct.mandatoryPool,
                                            mandatoryBBQ: itemSubCategoryOrProduct.mandatoryBBQ,
                                            mandatoryPergola: itemSubCategoryOrProduct.mandatoryPergola,
                                            needsApproval: itemSubCategoryOrProduct.needsApproval,
                                            priceDefinedByAdmin: itemSubCategoryOrProduct.priceDefinedByAdmin,
                                            qty: itemSubCategoryOrProduct.qty,
                                            automation: itemSubCategoryOrProduct.automatable == 1 || itemSubCategoryOrProduct.automationCenter == 1,
                                            automatable: itemSubCategoryOrProduct.automatable,
                                            automationCenter: itemSubCategoryOrProduct.automationCenter,
                                            channelQty: itemSubCategoryOrProduct.channelQty,
                                            unitySymbol: itemSubCategoryOrProduct.unitySymbol,
                                            note: itemSubCategoryOrProduct.note,
                                            styling: itemSubCategoryOrProduct.styling,
                                            customOrder: itemSubCategoryOrProduct.customOrder,
                                            stylable: itemSubCategoryOrProduct.stylable,
                                            price: formaterDecimalBR(itemSubCategoryOrProduct.price),
                                            total: itemSubCategoryOrProduct.total,
                                            requiredAtLeastOneProductCategory: itemSubCategoryOrProduct.requiredAtLeastOneProductCategory,
                                            requiredAtLeastOneProductSubCategory: itemSubCategoryOrProduct.requiredAtLeastOneProductSubCategory
                                        })
                                        grandTotal += parseFloat(total);
                                    }
                                }
                                else {
                                    itemSubCategoryOrProduct.qty = ''
                                }
                            }
                        });
                    });

                    this.grandTotalProductsUnFormatted = grandTotal;

                    if (this.grandTotalUpdated == false) {
                        // this.grandTotalProducts = `$ ${formaterDecimalBR(grandTotal)}`;
                        this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(grandTotal * this.og) / this.og))}`;
                    }
                }
            },

            async getEstimatedValueZillow() {

                let zillowRequest = {
                    typeRequest: 2, //Address
                    zipCodeOrAddress: "",
                }

                if (this.serviceRequest.addressSelected != null && this.serviceRequest.addressSelected != undefined) {
                    zillowRequest.zipCodeOrAddress = this.serviceRequest.addressSelected.address;
                    this.address = this.serviceRequest.addressSelected.address;
                }

                let response = await this.$vanKirkApi.post(`common/zillow`, zillowRequest, {
                        headers: {
                            "content-type": "application/json"
                        }
                    });
                let resultZillow = response.data.result;

                this.estimatedValueZillow = "$ 0,00";
                    
                if (resultZillow && resultZillow.success == true && resultZillow.bundle.length > 0) {
                    this.estimatedValueZillow = resultZillow.bundle[0].zEstimateFormatted;
                }
                else {
                    this.estimatedValueZillow = "Not found"
                }
            },

            focusInput(item) {
                this.panels = 0;
                this.arrayNode = []
                this.arrayNode.push(item.idCategory);
                if (item.idSubCategory != null && item.idSubCategory != undefined && item.idSubCategory != 0)  {
                    this.arrayNode.push(item.idSubCategory);
                }

                setTimeout(() => {
                    let txtComponent = document.getElementById(`productQty${item.idProduct}`);
                    txtComponent.focus();
                }, 100);

            },

            onOpenTreeView(items) {
                this.arrayNode = items
            },

            async cancel() {
                this.$router.push({ path: "/sales/pipeline" });
            },

            async confirmApprove() {
                
                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will APPROVE this Job and all projects, confirm your decision?',
                    methodConfirm: this.approve,
                    params: 0
                };
            },

            async approve() {

                if (await this.validateForm(1)) {
                
                    this.showLoading();

                    let updateStatusRequest = {
                        id: this.id,
                        status: 6 //Waiting Approval
                    };

                    let listBowlsAndProducts = [];
                    let idServiceProject = await this.getIDProject();

                    this.listProductSelecteds.forEach (item => {
                        listBowlsAndProducts.push({
                            id: item.id,
                            idServiceProject: idServiceProject,
                            idProduct: item.idProduct,
                            idBolws: 0,
                            qty: item.qty,
                            newQty: item.qty,
                            price: item.price,
                            notes: item.notes,
                        })
                    })
                    
                    const result = await this.$store.dispatch("serviceModule/CreateUpdateItems", { serviceItemRequest: listBowlsAndProducts, createHistory: false, oficial: 0, uploadAddendum: 0 });

                    if (result.success === true) {

                        let result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                        if (result.success) {
                            this.showToast("success", "Success!", "Proposal approved successfully!");
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Error!", result.message);
                        }

                        this.getRegister();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.hideLoading();
                }
            },       
            
            confirmDeny() {
               
               let statusId = 3

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DENY this service, confirm your decision?`,
                    methodConfirm: this.deny,
                    params: statusId,
                };
            },

            async deny(idStatus) {

                this.showLoading();

                let updateStatusRequest = {
                    id: this.id,
                    status: idStatus,
                    description: this.descriptionDenied
                };

                let result = await this.$store.dispatch("serviceModule/UpdateStatus", updateStatusRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Proposal denied successfully!");
                    this.cancel();
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.showDialogDeniedService = false;
                this.descriptionDenied = "";

                this.hideLoading();
            },

            async deleteItem(item) {
                
                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                if (itemProduct.idTable == item.idProduct) {
                                    itemProduct.qty = ''
                                }
                            });
                        }
                        else {
                            if (itemSubCategoryOrProduct.idTable == item.idProduct) {
                                itemSubCategoryOrProduct.qty = ''
                            }
                        }
                    });
                });


                await this.updateItemsProduct();
            },

            async checkMandatory() {

                let allFieldsOK = true;
                let listProductName = ''

                this.listTreeViewProduct.forEach(itemCategory => {
                    itemCategory.children.forEach(itemSubCategoryOrProduct => {
                        
                        if (itemSubCategoryOrProduct.type == 'SubCategory') {

                            itemSubCategoryOrProduct.children.forEach(itemProduct => {

                                allFieldsOK = this.validations.mandatoryProduct(itemProduct);

                                if (allFieldsOK == false) {
                                    listProductName += ` - ${itemProduct.name}<br />`;
                                }
                            });
                        }
                        else {
                            allFieldsOK = this.validations.mandatoryProduct(itemSubCategoryOrProduct);

                            if (allFieldsOK == false) {
                                listProductName += ` - ${itemSubCategoryOrProduct.name}<br />`;
                            }
                        }
                    });
                });

                if (listProductName != '') {
                    this.showToast("error", "Error!", `The items below are mandatory: <br /> <br /> ${listProductName}`);
                }

                return listProductName == '';
            },

            isProductMandatory(itemProduct) {
                return (itemProduct.mandatoryPool == 1    && this.isProjectPool == true) ||
                       (itemProduct.mandatoryBBQ == 1     && this.isProjectBBQ == true)  ||
                       (itemProduct.mandatoryPergola == 1 && this.isProjectPergola == true)
            },

            isCategoryOrSubCategoryMandatory(itemProduct) {

                const categoryIsMandatory = itemProduct.type == 'Category' && itemProduct.requiredAtLeastOneProductCategory == 1;
                const subCategoryIsMandatory = itemProduct.type == 'SubCategory' && itemProduct.requiredAtLeastOneProductSubCategory == 1;

                return (categoryIsMandatory == true) ||
                       (subCategoryIsMandatory == true)
            },

            async checkDiscount(discountValue) {
                const discountSalesPerson = this.userLoggedGetters.discount;

                if (discountValue > 0 && discountValue > discountSalesPerson) {
                    this.showToast('error', 'Warning', `Discount above the permitted ${this.userLoggedGetters.discountFormatted} %`);
                    this.discount = '0.00';
                    this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(this.grandTotalProductsUnFormatted * this.og) / this.og))}`;
                    this.grandTotalUpdated = false;
                    return false;
                }
                
                return true;
            },

            async calculateTotal(type) {

                if (type == 'd') { //discount
                    const discountUnformatted = parseFloat(this.discount);

                    if (await this.checkDiscount(discountUnformatted) == true) {
                        const newValue = this.grandTotalProductsUnFormatted - (this.grandTotalProductsUnFormatted * (discountUnformatted / 100));
                        this.grandTotalProducts = `$ ${formaterDecimalBR(Math.round(Math.floor(newValue * this.og) / this.og))}`;
                    }
                }
                else if (type == 'v') { //value
                    const newValueUnformatted = formaterDecimalBR(this.grandTotalProducts);
                    this.grandTotalUpdated = newValueUnformatted != this.grandTotalProductsUnFormatted;

                    if (newValueUnformatted < this.grandTotalProductsUnFormatted) {
                        const rest = this.grandTotalProductsUnFormatted - parseFloat(newValueUnformatted);
                        const newDiscount = Math.round(Math.floor(  ((rest / this.grandTotalProductsUnFormatted) * 100) * this.og) / this.og);

                        if (await this.checkDiscount(newDiscount) == true) {
                            this.discount = formaterDecimalBR(newDiscount);
                        }
                    }
                    else {
                        this.discount = '0.00';
                    }
                }
            },

            async showProductsImages(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT.value,
                    idKey: itemProduct.idProduct,
                    isImage: true
                })

                this.dialogShowFiles = {
                    show: true,
                    showAsCarrousel: false,
                    reload: Math.random(),
                    showFilters: false,
                    listParams
                };
            },

            async showProductsDocs(itemProduct) {

                let listParams = [];
                listParams.push({
                    typeAttachment: TYPE_ATTACHMENT.PRODUCT.value,
                    typeDocument: TYPE_DOCUMENT.PRODUCT_DOCS.value,
                    idKey: itemProduct.idProduct,
                    isImageAndDoc: true
                })

                this.dialogShowFiles = {
                    show: true,
                    reload: Math.random(),
                    showFilters: false,
                    listParams
                };
            },
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }
</style>

<style>
    input[name="qtyField"] {
        text-align: center !important;
    }

    input[name="moneyField"] {
        text-align: right !important;
    }

    .v-text-field__suffix {
        font-size: 12px !important;
        font-weight: bold;
    }

    .widthColumn {
        width: 8%;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {

        .widthColumn {
            width: 50%;
        }
    }
</style>